import { useTranslation } from 'react-i18next'

export default function MaintenanceWarning({ onAccept }) {
  const { t } = useTranslation()
  return (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6 z-50">
      <div className="pointer-events-auto ml-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
        <div className="space-y-2 text-sm leading-6 text-gray-900">
          <p>{t('landing.maintenanceWarning.line1')}</p>
          <p>{t('landing.maintenanceWarning.line2')}</p>
          <p>{t('landing.maintenanceWarning.line3')}</p>
        </div>
        <div className="mt-4 flex items-center">
          <button
            type="button"
            className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            onClick={onAccept}
          >
            {t('generic.understood')}
          </button>
        </div>
      </div>
    </div>
  )
}
